import { NavLink } from "react-router-dom";
import { AiFillEdit } from "react-icons/ai";
import { AiFillFolderOpen } from "react-icons/ai";

export default function ListTable(data) {
  const items = data.list.map(element => 

    <tr key={element.id}>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{element.id}</td>
      <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">{element.name}</td>
      <td className="text-sm text-gray-900 px-6 py-4 whitespace-nowrap">{element.folder.name}</td>
      <td>
        <NavLink
          className="button-primary inline-block w-14"
          to={'/admin/list/' + element.id + '/update'}
        >
          <AiFillEdit />
        </NavLink>

        <NavLink
          className="button-primary inline-block w-14"
          to={'/admin/list/' + element.id + '/view'}
        >
          <AiFillFolderOpen />
        </NavLink>
      </td>
    </tr>
  );

  return (
    <div>
      <table className="min-w-full">
        <thead className="border-b">
          <tr>
            <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Id</th>
            <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Nom</th>
            <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">Dossier</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items}
        </tbody>
      </table>
    </div>
    );
}