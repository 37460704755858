let routes = {
  'login': '/api/login_check',
  'get_folders': '/api/folders',
  'get_folder': '/api/:id/folder',
  'get_lists': '/api/lists/',
  'get_list': '/api/lists/list/:listing',

  'post_folder': '/api/:id/folder/update',
  'create_folder': '/api/folder/create',

  'create_list': '/api/lists/create',
  'update_list': '/api/lists/update',
  'delete_listingItem': '/api/lists/delete/:id/listingItem',

  'get_notes': '/api/notes',
  'get_note': '/api/notes/note/:id',
  'create_note': '/api/notes/create',
  'update_note': '/api/notes/update/:id',
};

function getRoutes(path, parameters, data = {}) {
  let url = process.env.REACT_APP_API_HOST + routes[path];

  if (parameters) {
    Object.keys(parameters).forEach(key => {
        url = url.replace(":"+key, parameters[key]);
    })
  }

  if (Object.keys(data).length) {
    url += queryParams(data);
  }

  return url;
}

function queryParams(params) {
  let query = '?';
  let n = 0;

  for (const[key, value] of Object.entries(params)) {
    if (n) {
      query += '&';
    }

    query += key + '=' + value;
    n++;
  }

  return query;
}

async function get(path, method, parameters, data, token) {
  return fetch(getRoutes(path, parameters, data), {
    method: method,
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    }
  }).then((response) => response.json());
}

async function set(path, method, parameters, data, token) {
  return fetch(getRoutes(path, parameters), {
    method: method,
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    },
    body:  JSON.stringify(data)
  }).then((response) => response.json());
}

export { getRoutes, get, set };
