import { NavLink } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { useState, useEffect } from "react";
import { get, getRoutes } from "../../components/ApiRouter";
import ListTable from './ListTable';
import ListSearch from './ListSearch';

function List() {
  const [user, setUser] = useOutletContext();
  const [list, setList] = useState([]);
  const [search, setSearch] = useState({
    archived: false
  });

  useEffect(() => {
    getLists();
  }, []);

  function getLists() {
    get('get_lists', "GET", {}, search, user.token).then(data => {
      setList(data.lists);
    });
  }

  return (
    <div>
      <NavLink
        className="button-primary inline-block"
        to={'/admin/list/create'}>
        Créer
      </NavLink>

      <ListSearch search={[search, setSearch]} research={getLists} />

      <h1 className="text-3xl font-bold mt-5">Liste</h1>
      {/* { JSON.stringify(user, null, 2) } */}

      <ListTable list={list} />
    </div>
  );
}

export default List;
