import React from 'react';
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Login from "../security/Login";
import { UserContext } from "../security/UserContext";


import Layout from "./Layout";

export default function Admin() {
  const [user, setUser] = React.useState({
    token: null,
    active: false,
    menu: true,
  });

  /**
   * If mobile, width siwe is less than 800px
   * Then we hide the menu on load
   */
  useEffect(() => {
    const {innerWidth} = window;

    if (innerWidth < 800) {
      setUser({
        ...user,
        menu: false
      });
    }
  }, []);

  /**
   * Toggle sidebar
   */
  function toggleSidebar() {
    setUser({
      ...user,
      menu: !user.menu
    });
  }

  const contentClass = ['right', 'block', 'bg-white', 'h-full', 'p-5', 'transition-all',
    user.menu ? 'ml-60' : ''].join(' ');

  return (
    <div>
        { 
          user.token ? 
          <>
            <UserContext.Provider value={[user, setUser]}>
              <Layout />
            </UserContext.Provider>

            <div className={contentClass}>
              <button className="-ml-5 -mt-6 burger" onClick={toggleSidebar}>
                <svg viewBox="0 0 100 80" width="20" height="20">
                  <rect width="100" height="20"></rect>
                  <rect y="30" width="100" height="20"></rect>
                  <rect y="60" width="100" height="20"></rect>
                </svg>
              </button>

              <div className="bg-white p-5 rounded-sm">
                <Outlet context={[user, setUser]} />
              </div>
            </div>
          </> :
          <UserContext.Provider value={[user, setUser]}>
            <Login user={[user, setUser]} />
          </UserContext.Provider>
        }
        {/* <br/>
        <br/>
        User : 
        { JSON.stringify(user, null, 2) } */}
    </div>
  );
}



