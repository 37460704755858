import { NavLink } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { useState, useEffect } from "react";
import { get, getRoutes } from "../../components/ApiRouter";
import { useParams } from "react-router-dom";
import ListTable from './ListTable';
import { AiOutlineCheckCircle } from "react-icons/ai";

function ListView() {
  let { listId } = useParams();
  const [user, setUser] = useOutletContext();
  const [list, setList] = useState({listingItems: []});

  useEffect(() => {
    getList();
  }, []);

  function getList() {
    get('get_list', "GET", {listing: listId}, {}, user.token).then(data => {
      setList(data.listing);
    });
  }

  //const textClass = ['td-bold',
  //  user.menu ? 'visible' : 'invisible'].join(' ');

    let textClass = (item) => {
      return ['td-bold',
      item.done ? 'text-blue-400' : ''].join(' ');
    }

  return (
    <div>
      <NavLink
        className="button-primary block w-20"
        to="/admin/list"
      >Retour</NavLink>

      <h1>{list.id} - {list.name} [{list.folder ? list.folder.name : '-'}]</h1>

      <table className="min-w-full">
        <thead className="border-b">
          <tr>
            <th className="th">#</th>
            <th className="th">Info</th>
            <th className="th">Mis en avant</th>
            <th className="th">Important</th>
            <th className="th">Terminé</th>
          </tr>
        </thead>
        <tbody>
          {
            list ? list.listingItems.map(item =>
              <tr key={item.id}>
                <td className="td-bold">{item.id}</td>
                <td className={textClass(item)}>{item.text}</td>
                <td className="td-bold">{item.highlight == true ? <AiOutlineCheckCircle/> : ''}</td>
                <td className="td-bold">{item.important == true ? <AiOutlineCheckCircle/> : ''}</td>
                <td className="td-bold text-center">{item.done == true ? <AiOutlineCheckCircle/> : ''}</td>
              </tr>
            ) : ''
          }
        </tbody>
      </table>

      <NavLink
        className="button-primary block w-20"
        to="/admin/list"
      >Retour</NavLink>
    </div>
  );
}

export default ListView;
