import App from '../App';
//import Login from './security/Login';
import Admin from '../admin/Admin';
import Logout from '../security/Logout';
import List from '../admin/list/List';
import ListCreate from '../admin/list/ListCreate';
import ListUpdate from '../admin/list/ListUpdate';
import ListView from '../admin/list/ListView';
import Folder from '../admin/folder/Folder';
import FolderEdit from '../admin/folder/FolderEdit';
import FolderCreate from '../admin/folder/FolderCreate';
import Note from '../admin/note/Note';
import NoteCreate from '../admin/note/NoteCreate';
import NoteEdit from '../admin/note/NoteEdit';

export default function Routes() {
  return [
    {
      path: "/",
      element: <App />,
    },
    // {
    //   path: "/login",
    //   element: <Login />,
    // },
    {
      path: "/logout",
      element: <Logout />,
    },
    {
      path: "/admin",
      element: <Admin />,
      children: [
        {
          path: 'list',
          element: <List />
        },
        {
          path: 'list/create',
          element: <ListCreate />
        },
        {
          path: 'list/:listId/update',
          element: <ListUpdate />
        },
        {
          path: 'list/:listId/view',
          element: <ListView />
        },
        {
          path: 'folder',
          element: <Folder />,
        },
        {
          path: 'folder/create',
          element: <FolderCreate/>
        },
        {
          path: 'folder/:folderId/edit',
          element: <FolderEdit/>
        },
        {
          path: 'note',
          element: <Note />
        },
        {
          path: 'note/create',
          element: <NoteCreate />
        },
        {
          path: 'note/:noteId/edit',
          element: <NoteEdit />
        },
      ]
    }
  ];
}