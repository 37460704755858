import { useOutletContext } from "react-router-dom";
import { useState, useEffect } from "react";
import { get } from "../../components/ApiRouter";
import FormButton from '../../components/button/FormButton';

export default function ListSearch(params) {
  const [search, setSearch] = params.search;
  const [folders, setFolders] = useState([]);
  const [user] = useOutletContext();

  useEffect(() => {
    getFolders();
  }, []);

  function getFolders(folderId){
    get('get_folders', "GET", {}, {}, user.token).then(data => {
      setFolders(data.folders);
    });
  }

  function update(e) {
    let fieldName = e.target.getAttribute('name');

    let filter = {};
    Object.keys(search).forEach(function(key, index) {
      if (search[key]) {
        filter[key] = search[key];
      }
    });

    setSearch({
      ...filter,
      [fieldName]: e.target.value,
    });
  }

  return <div>
    <h1>Rechercher</h1>

    <form>
      <div className="grid grid-cols-2 mb-4">
        <div className="px-4">
          <label>Nom</label>
          <input className="input" type="text" name="name" onChange={e => update(e)} />
        </div>
        <div className="px-4">
          <label>Dossier</label>
          <select className="input" name="folder" onChange={e => update(e)}>
            <option></option>
            {folders.map(folder =>
              <option key={folder.id} value={folder.id}>{folder.name}</option>
            )}
          </select>
        </div>
        <div className="px-4">
          <label>Archivé</label>
          <select className="input" name="archived" onChange={e => update(e)}>
            <option></option>
            <option value="0">Non</option>
            <option value="1">Oui</option>
          </select>
        </div>
      </div>

      <FormButton className="float-right" onClick={e => {e.preventDefault(); params.research(); }} name="Valider"></FormButton>
    </form>
  </div>;
}