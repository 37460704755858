import { useNavigate, useOutletContext } from "react-router-dom";
import { createRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { get, set } from "../../components/ApiRouter";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function NoteEdit() {
  let { noteId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useOutletContext();
  const [note, setNote] = useState({
    'title': '',
    'text': ''
  });
  let quill = createRef();

  useEffect(() => {
    getNote(noteId);
  }, []);

  function getNote(noteId) {
    get('get_note', "GET", {id: noteId}, {}, user.token).then(data => {
      let note = data.note;

      setNote({
        id: note.id,
        title: note.title,
        text: note.text,
      });
    });
  }

  function updateTitle(e) {
    setNote({
      ...note,
      title: e.target.value,
    });
  }

  function updateNote(e) {
    setNote({
      ...note,
      text: e.target.value,
    });
  }

  function save(e) {
    set('update_note', "POST", {id: note.id}, note, user.token).then(data => {
      navigate("/admin/note");
    });
  }

  return <>
    <h1 className="text-3xl font-bold">Editer une note</h1>

    <form onSubmit={e => { e.preventDefault(); save(); }}>
      <label className="mt-3 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Titre</label>
      <input className="input" type="text" name="title" defaultValue={note.title} onChange={updateTitle} />

      <label className="mt-3 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Texte</label>
      {/* <ReactQuill ref={quill} name="editor" theme="snow" defaultValue={note.text} onChange={updateNote} /> */}
      <textarea className="input" defaultValue={note.text} onChange={updateNote} rows="10"></textarea>

      <input type="submit" value="Enregistrer" className="button-primary" />
    </form>

    {/* { JSON.stringify(note, null, 2) } */}
  </>
}
