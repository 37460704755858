import { NavLink } from "react-router-dom";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { get, set } from "../../components/ApiRouter";

export default function ListUpdate() {
  const navigate = useNavigate();
  let { listId } = useParams();
  const [user, setUser] = useOutletContext();
  const [list, setList] = useState({});
  const [folderList, setFolderList] = useState([]);

  useEffect(() => {
    getFolderList();
    getList();
  }, []);

  function getFolderList(folderId){
    get('get_folders', "GET", {id: folderId}, {}, user.token).then(data => {
      setFolderList(data.folders);
    });
  }

  function getList(){
    get('get_list', "GET", {listing: listId}, {}, user.token).then(data => {
      setList(data.listing);
    });
  }

  function FolderSelect() {
    let selected = list.folder ? list.folder.id : '';

    return (
      <select
        className="input"
        value={selected}
        onChange={e => updateParent(e)}
      >
        <option></option>
        {folderList.map(element =>
          <option key={element.id} value={element.id}>
            {element.name}
          </option>
        )}
      </select>
    );
  }

  function updateName(e) {
    setList({
      ...list,
      name: e.target.value
    });
  }

  function updateParent(e) {
    folderList.forEach(element => {
      if (element.id == e.target.value) {
        setList({
          ...list,
          folder: element
        });
      }
    });
  }

  function save(e) {
    console.log(list);

    let newList = {
      id: list.id,
      name: list.name,
      folder: list.folder ? list.folder.id : null,
      listingItems: list.listingItems ? list.listingItems : [],
      archived: list.archived
    }

    console.log(newList);

    set('update_list', "POST", {}, newList, user.token).then(data => {
      navigate("/admin/list");
    });
  }

  function updateArchived(e) {
    setList({
      ...list,
      archived: e.target.value
    });
  }

  function updateListingName(e, key) {
    list.listingItems[key].text = e.target.value;
    setList({...list});
  }

  function updateListingDone(e, key) {
    list.listingItems[key].done = e.target.value == 'true' ? true : false;
    setList({...list});
  }

  function updateListingHighlight(e, key) {
    list.listingItems[key].highlight = e.target.value == 'true' ? true : false;
    setList({...list});
  }

  function updateListingImportant(e, key) {
    list.listingItems[key].important = e.target.value == 'true' ? true : false;
    setList({...list});
  }

  function addItem() {
    list.listingItems.push({
      text: '',
      done: false,
      important: false,
      highlight: false,
    });
    setList({...list});
  }

  function removeItem(key, id) {
    get('delete_listingItem', "GET", {id: id}, user.token).then(data => {
      list.listingItems.splice(key, 1);
      setList({...list});
    });
  }

  function ListingItem() {
    return (
      <div className="border-2 border-gray-300 p-3">
        {list.listingItems ? list.listingItems.map((element, key) => 
          <div key={key} className="p-3">
            <button className="button-primary float-right" onClick={e => {e.preventDefault(); removeItem(key, element.id);}}>x</button>
            <label className="label">Texte</label>
            <input className="input" type="text" onBlur={e => updateListingName(e, key)} defaultValue={element.text} />
            <br/>

            <label className="label">Terminé</label>
            <select value={element.done} onChange={e => updateListingDone(e, key)} className="input">
              <option value={true}>Oui</option>
              <option value={false}>Non</option>
            </select>

            <label className="label">Mis en avant</label>
            <select value={element.highlight} onChange={e => updateListingHighlight(e, key)} className="input">
              <option value={true}>Oui</option>
              <option value={false}>Non</option>
            </select>

            <label className="label">Important</label>
            <select value={element.important} onChange={e => updateListingImportant(e, key)} className="input">
              <option value={true}>Oui</option>
              <option value={false}>Non</option>
            </select>
            <br/>
            <hr className="border-2 border-gray-300" />
          </div>
        ) : ''}
        <button className="button-primary" onClick={e => {e.preventDefault(); addItem();}}>+</button>
      </div>
    );
  }

  return (
    <div>
      <NavLink
        className="button-primary block w-20"
        to="/admin/list"
      >Retour</NavLink>

      <h1>Mettre à jour la liste <b>{list.name}</b></h1>
      <form onSubmit={e => { e.preventDefault(); save(); }}>
        <label className="mt-3 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nom de la liste</label>
        <input className="input" type="text" defaultValue={list.name} onChange={e => {updateName(e)}} />

        <label className="mt-3 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Dossier</label>
        <FolderSelect />

        <label className="mt-3 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Items</label>
        <ListingItem />

        <label className="mt-3 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Archive</label>
        <select className="input" name="archived" defaultValue={list.archived} onChange={e => updateArchived(e)}>
          <option></option>
          <option value="0">Non</option>
          <option value="1">Oui</option>
        </select>

        <input className="button-primary" type="Submit" defaultValue="Sauvegarder" />
      </form>
      {/*<pre>
        { JSON.stringify(list, null, 2) }
      </pre>*/}
    </div>
  );
}
