import { useState, useContext, useEffect } from "react";
import { UserContext } from "./UserContext";
import { getRoutes } from "../components/ApiRouter";

function Login() {
  const [user, setUser] = useContext(UserContext);

  const [credentials, setCredentials] = useState({
    username: null,
    password: null
  });

  function updateCredentials(e, key) {
    setCredentials({
      ...credentials,
      [key]: e.target.value
    });
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      let decodedToken = parseJwt(token);
      let now = Math.ceil((new Date().getTime() + 1) / 1000)

      if (decodedToken == null) {
        localStorage.removeItem('token');
      }

      if (decodedToken.exp > now) {
        setUser({
          ...user,
          token: token,
          code: 200
        });
      } else {
        localStorage.removeItem('token');
      }
    }
  }, []);

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  function login() {
    fetch(getRoutes('login'), {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(credentials)
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);

      localStorage.setItem('token', data.token);

      setUser({
        ...user,
        token: data.token,
        code: data.code
      });
    });
  }

  const failureCss = ['transition-all', 'font-bold', 'text-yellow-500',
    user.code == 401 ? 'visible' : 'invisible'].join(' ');

  return (
    <div className="grid place-items-center">
      <h1 className="text-3xl font-bold">Login</h1>
      {/*{JSON.stringify(user, null, 2)}<br/>*/}
      {/*{JSON.stringify(credentials, null, 2)}<br/>*/}

      <p className={failureCss}>
        Mauvais login ou mot de passe
      </p>

      <form onSubmit={e => { e.preventDefault(); login(); }}>
        <label className="mt-3 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Pseudo</label>
        <input className="input" type="text" defaultValue="" onChange={e => {updateCredentials(e, 'username')}} />

        <label className="mt-3 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Mot de passe</label>
        <input className="input" type="password" defaultValue="" onChange={e => {updateCredentials(e, 'password')}} />

        <input
          className="inline-block mt-5 px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          type="Submit"
          defaultValue="Sauvegarder"
          onClick={login} />
      </form>

      {/*<button
        type="button"
        className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
        onClick={login}>
          Login
      </button>*/}
    </div>
  );
}

export default Login;
