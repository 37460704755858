export default function FormButton(params) {
  let classes = ['button-primary'].concat(params.className).join(' ');

  return <button 
    onClick={params.onClick}
    className={classes}
  >
    {params.name}
  </button>
}
