import { NavLink } from "react-router-dom";
import { AiFillEdit } from "react-icons/ai";

export default function Table(data) {
  const listItems = data.folders.map(element =>
    <tr key={element.id} className="border-b">
      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{element.id}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{element.name}</td>
      <td>
        <NavLink
          className="button-primary block w-6"
          to={'/admin/folder/' + element.id + '/edit'}
        >
          <AiFillEdit />
        </NavLink>
      </td>
    </tr>
  );

  return (
    <table className="min-w-full">
      <thead className="border-b">
        <tr>
          <th className="th">Id</th>
          <th className="th">Dossier</th>
          <th className="th">Actions</th>
        </tr>
      </thead>

      <tbody>
        {listItems}
      </tbody>
    </table>
  );
}
