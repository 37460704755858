import { useNavigate, useOutletContext } from "react-router-dom";
import { useState, useEffect } from "react";
import { get, set } from "../../components/ApiRouter";
import { useParams } from "react-router-dom";

export default function FolderEdit(data) {
  let { folderId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useOutletContext();
  const [folder, setFolder] = useState({});
  const [folderList, setFolderList] = useState([]);

  useEffect(() => {
    getFolder(folderId);
    getFolderList();
  }, []);

  function getFolder(folderId){
    get('get_folder', "GET", {id: folderId}, {}, user.token).then(data => {
      setFolder(data.folder);
    });
  }

  function getFolderList(folderId){
    get('get_folders', "GET", {id: folderId}, {}, user.token).then(data => {
      setFolderList(data.folders);
    });
  }

  function save(e) {
    set('post_folder', "POST", {id: folderId}, {name: folder.name, parent: folder.parent.id}, user.token).then(data => {
      navigate("/admin/folder");
    });
  }

  function updateName(e) {
    setFolder({
      name: e.target.value,
      parent: folder.parent
    });
  }

  function updateParent(e) {
    setFolder({
      name: folder.name,
      parent: {
        id : e.target.value,
        name: folderList[e.target.value]
      }
    });
  }

  function FolderSelect() {
    let selected = folder.parent ? folder.parent.id : '';

    return (
      <select
        className="input"
        value={selected}
        onChange={e => updateParent(e)}
      >
        <option></option>
        {folderList.map(element =>
          <option key={element.id} value={element.id}>
            {element.name}
          </option>
        )}
      </select>
    );
  }

  return (
    <div>
      <h1>Modifier un dossier</h1>
      <form onSubmit={e => { e.preventDefault(); save(); }}>
        <label className="mt-3 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nom du dossier</label>
        <input className="input" type="text" defaultValue={folder.name} onChange={e => {updateName(e)}} />

        <label className="mt-3 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Parent</label>
        <FolderSelect />

        <input className="button-primary" type="Submit" defaultValue="Sauvegarder" />
      </form>
    </div>
  );
}
