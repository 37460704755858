import { NavLink } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { useState, useEffect } from "react";
import { get } from "../../components/ApiRouter";
import Table from "./Table";

function Folder() {
  const [user, setUser] = useOutletContext();
  const [folders, setFolders] = useState([]);

  useEffect(() => {
    getFolders();
  }, []);

  function getFolders(folderId){
    get('get_folders', "GET", {}, {}, user.token).then(data => {
      setFolders(data.folders);
    });
  }

  return (
    <div>
      <NavLink
        className="button-primary inline-block"
        to={'/admin/folder/create'}>
        Créer
      </NavLink>

      <h1 className="text-3xl font-bold">Dossiers</h1>

      {/* { JSON.stringify(user, null, 2) } */}
      <ul>
        <Table folders={folders} />
      </ul>
    </div>
  );
}

export default Folder;
