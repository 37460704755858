import { useNavigate, useOutletContext } from "react-router-dom";
import { useState, useEffect } from "react";
import { get, set } from "../../components/ApiRouter";

export default function ListCreate() {
  const navigate = useNavigate();
  const [user, setUser] = useOutletContext();
  const [list, setList] = useState({});
  const [folderList, setFolderList] = useState([]);

  useEffect(() => {
    getFolderList();
  }, []);

  function getFolderList(folderId){
    get('get_folders', "GET", {id: folderId}, {}, user.token).then(data => {
      setFolderList(data.folders);
    });
  }

  function FolderSelect() {
    let selected = list.folder ? list.folder.id : '';

    return (
      <select
        className="input"
        value={selected}
        onChange={e => updateParent(e)}
      >
        <option></option>
        {folderList.map(element =>
          <option key={element.id} value={element.id}>
            {element.name}
          </option>
        )}
      </select>
    );
  }

  function updateName(e) {
    setList({
      ...list,
      name: e.target.value
    });
  }

  function updateParent(e) {
    folderList.forEach(element => {
      if (element.id == e.target.value) {
        setList({
          ...list,
          folder: element
        });
      }
    });
  }

  function save(e) {
    let newList = {
      name: list.name,
      folder: list.folder ? list.folder.id : null,
      archived: false,
    }

    set('create_list', "POST", {}, newList, user.token).then(data => {
      let path = "/admin/list/"+data.id+"/update";
      navigate(path);
    });
  }

  return (
    <div>
      <h1>Créer une liste</h1>
      <form onSubmit={e => { e.preventDefault(); save(); }}>
        <label className="mt-3 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nom de la liste</label>
        <input className="input" type="text" defaultValue={list.name} onChange={e => {updateName(e)}} />

        <label className="mt-3 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Dossier</label>
        <FolderSelect />

        <input className="button-primary" type="Submit" defaultValue="Sauvegarder" />
      </form>
    </div>
  );
}
