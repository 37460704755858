import { NavLink } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { useState, useEffect } from "react";
import { get } from "../../components/ApiRouter";
import parse from 'html-react-parser';

export default function Note()
{
  const [user, setUser] = useOutletContext();
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    getFolders();
  }, []);

  function getFolders(folderId){
    get('get_notes', "GET", {}, {}, user.token).then(data => {
      setNotes(data.notes);
    });
  }

  return <>
    <h1 className="text-3xl font-bold">Hey note</h1>

    <NavLink
      className="button-primary inline-block"
      to={'/admin/note/create'}>
      Créer
    </NavLink>

    <table className="min-w-full">
      <thead className="border-b">
        <tr>
          <th className="th">ID</th>
          <th className="th">Titre</th>
          <th className="th">Texte</th>
          <th className="th">Action</th>
        </tr>
      </thead>
      <tbody>
        {notes.map(note => (
          <tr key={note.id} className="border-b">
            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{note.id}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{note.title}</td>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {parse(note.text)}
              {/*JSON.stringify(note.text)*/}
            </td>
            <td>
              <NavLink
                className="button-primary inline-block"
                to={'/admin/note/' + note.id + '/edit'}>
                Editer
              </NavLink>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </>
}
