import { NavLink } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../security/UserContext";
import { AiOutlineLeftSquare } from "react-icons/ai";

function Layout() {
  const [user, setUser] = useContext(UserContext);

  const sidebar = ['left', 'fixed', 'w-60', 'inline-block', 'h-full', 'bg-green-50', 'p-5',
    'truncate', // Can be deleted later... After layout is fully tested working ok
    user.menu ? 'visible' : 'invisible'].join(' ');

  function getCurrentWeek() {
    let currentDate = new Date();
    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    var days = Math.floor((currentDate - startDate) /
      (24 * 60 * 60 * 1000));

    return Math.ceil(days / 7);
  }

  function getCurrentDate() {
    return new Date().toJSON().slice(0, 10);
  }

  return (
    <>
      <div className={sidebar}>
        <h1 className="text-3xl font-bold mt-5">Hej Choppy</h1>
        <h1 className="text-3sl font-bold mt-5">
          {getCurrentDate()}<br/>
          Semaine {getCurrentWeek()}
        </h1>
        <NavLink
          className="button-primary block"
          to="/admin/list"
        >
          Listes
        </NavLink>

        <NavLink
          className="button-primary block"
          to="/admin/folder">
            Dossiers
        </NavLink>

        <NavLink
          className="button-primary block"
          to="/admin/note">
            Notes
        </NavLink>

        <NavLink
          className="button-primary absolute bottom-px"
          to="/logout">
            <AiOutlineLeftSquare /> Se déconnecter
        </NavLink>
        {/*{ JSON.stringify(user, null, 2) }*/}
      </div>
    </>
  );
}

export default Layout;
