import { useNavigate, useOutletContext } from "react-router-dom";
import { createRef, useState } from "react";
import { set } from "../../components/ApiRouter";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function NoteCreate() {
  const navigate = useNavigate();
  const [user, setUser] = useOutletContext();
  const [note, setNote] = useState({
    'title': '',
    'text': '',
  });
  let quill = createRef();

  function updateTitle(e) {
    setNote({
      ...note,
      title: e.target.value,
    });
  }

  function updateNote(e) {
    setNote({
      ...note,
      text: e.target.value,
    });
  }

  function save(e) {
    set('create_note', "POST", {}, note, user.token).then(data => {
      navigate("/admin/note");
    });
  }

  return <>
    <h1 className="text-3xl font-bold">Ajouter une note</h1>

    <form onSubmit={e => { e.preventDefault(); save(); }}>
      <label className="mt-3 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Titre</label>
      <input className="input" type="text" name="title" onChange={updateTitle} />

      <label className="mt-3 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Texte</label>
      {/* <ReactQuill ref={quill} name="editor" theme="snow" defaultValue={note} onChange={updateNote} /> */}
      <textarea className="input" defaultValue={note.text} onChange={updateNote}></textarea>

      <input type="submit" value="Enregistrer" className="button-primary" />
    </form>

    {/* { JSON.stringify(note, null, 2) } */}
  </>
}
